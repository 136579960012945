.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('assets/background-image.png');
  background-size: cover;
  z-index: -1;
}

.logo-container {
  background-image: url('assets/aincardLogo.png');
  width: 100%;
  aspect-ratio: 2.5;
  background-size: contain;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
}

label {
  font-size: 20px;
}

input {
  width: 93%;
  padding: 12px;
  font-size: 18px;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: #F1ECEC;
  border: none;
  outline-color: #5A33A4;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
}

.login-button {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  margin-top: 15px;
  background-color: #5A33A4;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.login-button:hover {
  background-color: #251444;
}

.login-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: rgba(255, 255, 255);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  width: 25%;
}

.login-table {
  width: 100%;
}

@media (max-width: 900px) {
  .login-box {
    width: 65%;
  }
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('assets/background-image.png');
    background-size: cover;
    z-index: -1;
  }
}
