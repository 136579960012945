.dashboard {
    display: flex;
    background-color: #F8F8F8;
  }
  body {
    background-color: #F8F8F8;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  }
  
  .menu {
    background-image: url("assets/menu-background.png");
    background-size: cover;
    color: white;
    width: 20vw; /* Ancho en desktop */
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow-y: auto;
    transform: translateX(-20vw);
    transition: transform 0.3s ease;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.15);
  }
  
  .menu.show {
    transform: translateX(0);
  }
  
  .logo {
    padding: 30px;
    margin-top: 30px;
    font-size: 24px;
    font-weight: bold;
    background-image: url(assets/aincardLogo.png);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 2.5;
    width: 55%;
  }
  
  .menu-item {
    background-color: transparent;
    border: none;
    display: block;
    color: white;
    padding: 10px 20px;
    text-align: left;
    font-size: 17px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    cursor: pointer;
    width: 100%;
    box-shadow: none;
  }
  
  .menu-item:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .logout-btn {
    background-color: transparent;
    border: none;
    display: block;
    color: white;
    padding: 10px 20px;
    text-align: left;
    font-size: 17px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    position: absolute;
    bottom: 20px;
    box-shadow: none;
  }

  .logout-btn:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .content {
    background-color: #F8F8F8;
    width: 80vw; /* Ancho en desktop */
    padding: 55px;
    margin-left: 20vw; /* Margen izquierdo igual al ancho del menú */
  }

  .top-table {
    width: 100%;
  }

  .top-subtable {
    width: 100%;
  }

  .admin-icon-cell {
    width: 50px;
  }

  .admin-icon-image {
    width: 50px;
    background-image: url('assets/admin-icon.png');
    background-size: cover;
    aspect-ratio: 1;
    border-radius: 25px;
  }
  
  .container {
    padding: 0px;
  }
  
  .table-row {
    text-align: right;
    align-items: end;
  }
  
  .menu-btn {
    width: 36px;
    aspect-ratio: 1;
    background-image: url(./assets/menu-icon.png);
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 10;
    border-radius: 18px;
  }

  .activate-sound-btn {
    width: 100px;
    padding: 10px;
    background-color: #6F28BF;
    border: none;
    cursor: pointer;
    margin: 0;
    border-radius: 18px;
    font-size: 18;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
  }


  .activate-sound-btn:hover {
    background-color: #a56aea;
  }

  .deactivate-sound-btn {
    width: 100px;
    padding: 10px;
    background-color: #37B5FF;
    border: none;
    cursor: pointer;
    margin: 0;
    border-radius: 18px;
    font-size: 18;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
  }

  .deactivate-sound-btn:hover {
    background-color: #75c7f7;
  }
  
  .menu-btn:hover {
    background-image: url(assets/menu-icon-hover.png);
  }

  .menu-button-cell {
    vertical-align: top;
    display: none;
  }

  .first-row-admin {
    width: 100%;
    text-align: right;
  }
  
  .menu-btn:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .info-column {
    text-align: right;
  }
  
  .icon-column {
    margin-left: 10px;
    width: 40px;
  }

  .info-name-lbl {
    font-weight: bold;
    color: #181D31;
  }
  
  /* Contenedor de la barra de búsqueda */
  .search-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  }

  .destinataries-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  }

  .scrollable-dest {
    max-height: 100px; /* Altura máxima */
    overflow-y: auto;  /* Scroll si el contenido es mayor a la altura */
  }

  .search-container-cell {
    width: 50%;
  }
  
  .users-menu-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: url("assets/users-menu.png");
    background-size: cover;
    margin-right: 15px;
    margin-left: 20px;
  }

  .send-email-menu-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: url("assets/send-email-menu.png");
    background-size: cover;
    margin-right: 15px;
    margin-left: 20px;
  }

  .modify-user-menu-image {
    width: 20px;
    height: 20px;
    background-image: url("assets/modify-user-menu.png");
    background-size: cover;
    margin-right: 15px;
    margin-left: 20px;
  }

  .small-icon-cell {
    width: 20px;
    height: 20px;
    padding: 0;
  }

  .modify-user-email-icon {
    width: 20px;
    height: 20px;
    background-image: url("assets/modify-user-email-icon.png");
    background-size: cover;
  }

  .modify-user-phone-icon {
    width: 20px;
    height: 20px;
    background-image: url("assets/modify-user-phone-icon.png");
    background-size: cover;
  }

  .modify-user-email-phone-cell {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-size: 18px;
    text-align: left;
    padding-left: 12px;
  }

  .aincard-i-am-catalog-img {
    height: 100%;
    background-image: url("assets/aincard-i-am-catalog.png");
    aspect-ratio: 1.2;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 12px;
  }

  .ainsound-catalog-img {
    width: 40%;
    background-image: url(assets/song-catalog-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 1;
    margin-right: 20px;
    display: flex; /* Habilita Flexbox */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    text-align: center; /* Opcional: centra el texto */
    color: white;
  }

  .right-margin {
    margin-right: 10px;
  }

  .top-margin {
    margin-top: 10px;
  }

  .logout-menu-image {
    width: 20px;
    height: 20px;
    background-image: url("assets/logout-menu.png");
    background-size: cover;
    margin-right: 15px;
    margin-left: 20px;
  }

  .page-title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 28px;
  }

  .page-desc {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 200;
    font-size: 16px;
  }

  .page-desc2 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
  }

  /* Contenedor del icono de búsqueda */
  .search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  
  /* Imagen de perfil circular */
  .profile-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-image: url("assets/searchbar-image.png");
    background-size: cover;
  }
  
  /* Estilo del campo de entrada */
  .search-input {
    flex: 1;
    padding: 10px;
    margin: 0;
    padding-left: 10px; /* Ajustar el padding izquierdo para que el texto no esté muy lejos de la imagen */
    border: none;
    border-radius: 25px;
    outline: none;
    font-size: 16px;
    background-color: transparent;
  }
  
  /* Estilo del botón de búsqueda */
  .search-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #490C91;
    font-size: 18px;
    margin-left: 10px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: bold;
    box-shadow: none;
  }
  
  .search-button:hover {
    color: #6F28BF;
  }
  
  .close-menu-button {
    box-shadow: none;
    font-weight: bold;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 27px;
    width: 100%;
    text-align: right;
    padding-right: 35px;
    padding-top: 20px;
    display: none;
  }

  .search-individual-table {
    width: 100%;
  }
  



  .results-content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
  
  .user-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px 40px;
    width: 90%;
  }

  .modify-user-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 40px;
    margin-top: 20px;
    width: 90%;
  }
  
  .desktop-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .desktop-row-ainsounds {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
  }

  .desktop-half-row {
    display: flex;
    flex-direction: row;
    justify-content:center;
    width: 33%;
  }

  .sub-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: fit-content;
  }

  .user-info {
    display: flex;
    flex-direction: column;
  }

  .page-variable-content {
    width: 100%;
  }

  .vertical-padding-lbl {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 17px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  }
  
  .user-details-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-image: url("assets/user-details-btn.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    box-shadow: none;
  }
  
  .user-details-btn img {
    width: 100%;
    height: 100%;
  }

  .user-details-btn:hover {
    background-image: url("assets/user-details-btn-hover.png");
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .pagination-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  
  .pagination-btn.disabled {
    color: grey;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 14px;
  }

  .ainsounds-catalog-title {
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    width: 40vw;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .email-popover-content {
    background: #F8F8F8;
    width: 80vw;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    max-height: 80vh; /* Limita la altura del popover al 80% de la ventana */
    overflow: hidden; /* Asegura que solo la lista sea scrolleable */
  }

  .email-popover-list-box {
    display:flex;
    flex-direction: column;
    background: white;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    
  }

  .scrollable {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-grow: 1; /* Se expande para ocupar el espacio disponible */
    overflow-y: auto; /* Habilita el desplazamiento si el contenido supera la altura */
    max-height: 30vh; /* Ajusta este valor según el espacio que quieras asignar a la lista */
  }
  
  .modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }

  .modal-buttons-split {
    margin-top: 20px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
  }
  
  .modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 19px;
    cursor: pointer;
  }
  
  .modal-button-blue {
    background-color: #37B5FF;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 18px;
    margin-left: 10px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: bold;
    box-shadow: none;
  }

  .modal-button-blue:hover {
    background-color: #75c7f7;
  }
  
  .modal-button-purple {
    background-color: #490C91;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 18px;
    margin-left: 10px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: bold;
    box-shadow: none;
  }
  
  .modal-button-purple:hover {
    background-color: #6F28BF;
  }


  .filter-container {
    display: flex;
    justify-content: left;
    margin: 10px 0;
  }

  .mini-filter-container {
    display: flex;
    justify-content: left;
    margin: 10px 0;
  }

  .dest-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 10px 0;
  }

  .title-dest-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filter-label {
      margin: 0 10px;
      font-size: 16px;
      display: flex;
      align-items: center;
  }

  .filter-label input[type="radio"] {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      border: 2px solid #37B5FF;
      border-radius: 50%;
      position: relative;
  }

  .filter-label input[type="radio"]::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #37B5FF;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.2s;
  }

  .filter-label input[type="radio"]:checked::before {
      opacity: 1;
  }

  .user-status {
      color: #490C91;
      margin-right: 10px;
      font-weight: bold;
  }

  /* Ocultar el checkbox original */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Estilo para el contenedor del checkbox */
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Estilo del checkmark (cuadro) */
.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #3b3b3b;
  margin-right: 10px;
  position: relative;
}

/* Cambiar el fondo cuando está seleccionado */
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #490C91; /* Color personalizado cuando está seleccionado */
  border-color: #490C91; /* Borde también se cambia */
}

/* Estilo del checkmark cuando está seleccionado (ícono o símbolo) */
.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Mostrar el checkmark cuando el checkbox está seleccionado */
.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}

  
  
  /* Estilos para dispositivos móviles */
  @media (max-width: 700px) {
    .modal-content {
        background: white;
        width: 80vw;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        text-align: center;
      }

    .menu {
      width: 100vw; /* Ancho en móvil */
      transform: translateX(-100%);
    }
  
    .content {
      width: 100%; /* Ancho en móvil */
      margin-left: 0;
      padding: 30px;
    }

    .close-menu-button {
        display: block;
    }

    .menu-button-cell {
        width: 25%;
        display: block;
    }

    .first-row-admin {
        column-span: 1;
    }

    .search-container-cell {
        width: 75%;
    }

    .search-button {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-left: 5px;
        width: 20%;
    }

    .user-container {
        width: 75%;
    }

    .desktop-row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    .desktop-row-ainsounds {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }

    .desktop-half-row {
      display: flex;
      flex-direction: row;
      justify-content:center;
      width: 100%;
      margin-bottom: 10%;
    }

    .desktop-half-row {
      width: 100%;
    }

    .modify-user-holder {
      padding: 15px 30px;
      width: 80%;
    }

    .aincard-i-am-catalog-img {
      width: 35vw;
      margin-right: 10px;
      margin-left: 10px;
    }

    .sub-row {
      margin-top: 25px;
      justify-content: center;
      align-items: center;
    }

    .filter-container {
      flex-direction: column;
      align-items: flex-start; /* Alinea a la izquierda */
      justify-content: space-between;
      margin: 0;
    }

    .filter-label {
        margin-bottom: 0px; /* Añade espacio entre cada elemento */
    }

    .modal-button {
        align-self: flex-start; /* Centra el botón en la columna */
    }

    .scrollable {
      max-height: 20vh; /* Ajusta este valor según el espacio que quieras asignar a la lista */
    }

    .email-popover-content {
      max-height: 95vh;
    }
  }
  